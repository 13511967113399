@charset "utf-8";
@import url('https://rsms.me/inter/inter.css');

/* ===== General===== */

$body-background-color: #010203;
$body-color: #fff !default;
$text: #fff;
$title-color: #fff;
$strong-color: #fff;
$body-color: #fff;
$grey-dark: #fff;
$link-color: #fff;
$fullhd-enabled: false;
$widescreen-enabled: false;
$body-family: Inter,BlinkMacSystemFont,-apple-system,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Fira Sans','Droid Sans','Helvetica Neue',Helvetica,Arial,sans-serif;

@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/aos/src/sass/aos.scss';

html {
  background: $body-background-color url('../static/background.svg') no-repeat center;
  background-size: 70% auto;
  background-attachment: fixed;
}

/* ===== Sections ===== */

section {
  box-sizing: border-box !important;
  min-height: 56vh;
  display: flex;
  align-items: center;
  padding: 100px 32px !important;

  &:first-child {
    margin-top: 22vh;
  }

  &:last-child {
    margin-bottom: 22vh;
  }

  h3.title {
    font-weight: 600;
    font-size: 48px;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 24px;
  }

  h4.title {
    font-weight: 700;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 12px !important;
  }

  a {
    color: #fff !important;
    font-weight: 700;
    border-bottom: 1px solid #fff;
  }
}

.we-arent .title {
  line-height: 1.5;
  font-size: 2.4rem;
}

.we-are .title {
  line-height: 1.1;
  font-size: 8em;
}

.brands img {
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

.program {
  background: transparent no-repeat center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
  background-size: cover;
}

.team {
  background: #000;

  .team-member {
    margin-top: 64px;

    img {
      float: left;
      width: 198px;
    }

    .team-member-inner {
      margin-left: 262px;
    }
  }
}

.slideshow {
  height: 360px;
  min-height: 360px;
  background: #000;
  padding: 0 !important;

  .slideshow-container {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1;

    img {
      width: auto;
      height: 360px;
      margin-right: 20px;
    }
  }
}

.contact {
  background: transparent no-repeat center;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 60%, #000000 100%);
  background-size: cover;
  text-align: center;
}

/* ===== Mobile ===== */

@media (max-width: 767px) {
  body {
    background: $body-background-color url('../static/background-mobile.svg') no-repeat 28px 32px;
    background-size: auto 48px;
    background-attachment: scroll;
  }

  section {
    padding-top: 40px;
    padding-bottom: 40px;

    p {
      font-size: 18px;
    }
  }
  .title {
    zoom: 0.7;
  }

  .we-are .title {
    font-size: 7.8em;
  }
  .we-are .title span {
    display: block;
  }

  .brands img {
    max-width: 100%;
  }

  .team .team-member img {
    width: 64px;
  }
  .team .team-member .team-member-inner {
    margin-left: 96px;
  }
}
